import React, { useState, useRef, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { Download, PencilFill, Plus, X } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal, DeleteModal } from '../../components/Modal';
import { CheckboxTablePaginated } from '../../components/ReactTable/ReactTableServerSidePagination';
import CheckpointDetails from './CheckpointDetails';
import CheckpointAPIs from '../../api/checkpoints';
import { withToast } from '../../api/util';
import { TitleComponent } from '../../components/TitleComponent';
import { download_file } from '../Raffles/downloadFile';

const Checkpoints = () => {
  const [checkpoints, setCheckpoints] = useState([]);
  const [selectedCheckpoint, setSelectedCheckpoint] = useState(null);
  const [objEditDelete, setObjEditDelete] = useState(null);
  const [showModal, setShowModal] = useState('');
  const [language, setLanguage] = useState('hu');
  const fetchIdRef = useRef(0);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const selectedCheckpoints = useRef([]);
  const [refresh, setRefresh] = useState(false);


  const { t } = useTranslation();

  const fetchData = useCallback(
    ({ pageIndex, pageSize, filters }) => {
      setLoading(true);
      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        let queryObj = {};
        if (filters && filters.length > 0) {
          filters.forEach(function (filter) {
            if (filter?.value) {
              queryObj[filter.id] = filter.value;
            }
          });
        }
        queryObj['_pagesize'] = pageSize;
        queryObj['_page'] = pageIndex + 1;
        getCheckpoints(queryObj);
      }
    },
    [language, refresh]
  );

  const getCheckpoints = async (queryObj) => {
    try {
      let res = await CheckpointAPIs.getCheckpoints(
        language,
        queryObj ? queryObj : null
      );
      setPageCount(res.headers.total_pages);
      setCheckpoints(res.data);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const createCheckpoint = async (obj) => {
    try {
      let res = await withToast(CheckpointAPIs.createCheckpoint(obj, language));
      setSelectedCheckpoint(res.data);
      setShowModal('');
      setRefresh(!refresh);
    } catch (err) {
      console.error(err);
    }
  };

  const editCheckpoint = async (obj) => {
    try {
      const id = obj.id;
      delete obj.id;
      await withToast(CheckpointAPIs.editCheckpoint(id, obj, language));
      setShowModal('');
      setRefresh(!refresh);
    } catch (err) {
      console.error(err);
    }
  };

  const deleteCheckpoint = async () => {
    try {
      await withToast(CheckpointAPIs.deleteCheckpoint(objEditDelete));
      setSelectedCheckpoint(null);
      setShowModal('');
      setRefresh(!refresh);
    } catch (err) {
      console.error(err);
    }
  };

  const deleteAll = async () => {
    try {
      const obj = {
        checkpoints_ids_to_delete: selectedCheckpoints.current,
      };
      await withToast(CheckpointAPIs.deleteCheckpoints(obj));
      setSelectedCheckpoint(null);
      setShowModal('');
      setRefresh(!refresh);
    } catch (err) {
      console.error(err);
    }
  };

  const exportQrCodes = async (id) => {
    
    const obj = {
      checkpoint_ids_to_export: id ? [id] : selectedCheckpoints.current
    }
    try {
      const res = await withToast(CheckpointAPIs.exportCheckpoints(obj));
      download_file(res.res);
    } catch (err) {
      console.error(err);
    }
  };

  const cell = (row) => {
    return <div className="pull-right">{row.value}</div>;
  };

  const columns = [
    {
      Header: t('name'),
      accessor: 'name',
      Cell: cell,
    },
  ];

  const columns2 = [
    {
      Header: t('name'),
      accessor: 'name',
      Cell: cell,
    },
    {
      Header: t('audio'),
      accessor: 'audio',
      Cell: cell,
    },
    {
      Header: t('description'),
      accessor: 'description',
      Cell: cell,
    },
    {
      Header: t('buttons.export_qr_codes'),
      accessor: 'qr_code',
      Cell: (row) => {
        return (
          <div className="text-center">
            <Download
              className="custom-icon me-3"
              color="#AF9B78"
              size={18}
              onClick={() => exportQrCodes(row.row.original.id)}
            />
          </div>
        );
      },
      width: 100,
      disableFilters: true
    },
    {
      Header: '',
      accessor: 'actions',
      Cell: (row) => {
        return (
          <div className="text-center">
            <PencilFill
              className="custom-icon me-1"
              color="gray"
              size={16}
              onClick={() => {
                setShowModal('details');
                setSelectedCheckpoint(row.row.original);
              }}
            />
            <X
              className="custom-icon"
              color="red"
              size={20}
              onClick={() => {
                setShowModal('delete');
                setObjEditDelete(row.row.original.id);
              }}
            />
          </div>
        );
      },
      disableFilters: true,
      width: 'auto',
      minWidth: 60,
      maxWidth: 60,
    },
  ];

  return (
    <>
      <TitleComponent
        title={t('checkpoints')}
        language={language}
        setLanguage={setLanguage}
      />
      <Button
        size="sm"
        className="mb-2 custom-button"
        onClick={() => {
          setSelectedCheckpoint(null);
          setShowModal('details');
        }}
      >
        <span>
          <Plus color="white" size={20} />
        </span>
        {t('buttons.add')}
      </Button>
      <div className='float-end'>
        <Button
          size="sm"
          className="mb-2 custom-button me-2"
          onClick={() => {
            setSelectedCheckpoint(null);
            setShowModal('export_qr_codes');
          }}
        >
          <span className='pe-2'>
            <Download color="white" size={15} />
          </span>
          {t('buttons.export_qr_codes')}
        </Button>
        <Button
          size="sm"
          className="mb-2 custom-button"
          onClick={() => {
            setSelectedCheckpoint(null);
            setShowModal('delete_all');
          }}
        >
          <span>
            <X color="white" size={20} />
          </span>
          {t('buttons.delete_checkpoints')}
        </Button>
      </div>
      <CheckboxTablePaginated
        columns={columns2}
        data={checkpoints}
        total={false}
        filterTable={true}
        pagination={true}
        selectedId={selectedCheckpoint?.id}
        fetchData={fetchData}
        pageCount={pageCount}
        loading={loading}
        onSelectedRowsChange={(rows) => {
          if(rows?.length > 0) {
            selectedCheckpoints.current = rows;
          }
        }}
        autoResetSelectedRows={true}
      />
      {showModal === 'details' && (
        <CheckpointDetails
          onCancel={() => setShowModal('')}
          selectedRow={selectedCheckpoint}
          onCreate={(obj) => createCheckpoint(obj)}
          onEdit={(obj) => editCheckpoint(obj)}
          language={language}
          onRefresh={() => setRefresh(!refresh)}
        />
      )}
      {showModal === 'delete' && (
        <DeleteModal
          onOK={() => deleteCheckpoint()}
          onCancel={() => setShowModal('')}
        />
      )}

      {showModal === 'delete_all' && (
        <DeleteModal
          content={
            selectedCheckpoints.current.length > 0
            ? <>
              <div>
                {t('messages.checkpoints_will_be_deleted', {
                  count: selectedCheckpoints.current.length
                })}
              </div>
              <div>{t('messages.want_to_delete')}</div>
            </>
            : <>
              <div>{t('messages.checkpoint_nothing_selected')}</div>
            </>
          }
          onOK={() => deleteAll()}
          onCancel={() => setShowModal('')}
        />
      )}

      {showModal === 'export_qr_codes' && (
        <ConfirmationModal
          content={
            selectedCheckpoints.current.length > 0
            ? <>
              <div>
                {t('messages.checkpoints_will_be_exported', {
                  count: selectedCheckpoints.current.length
                })}
              </div>
            </>
            : <>
              <div>{t('messages.checkpoint_nothing_selected')}</div>
            </>
          }
          okButtonText={t('buttons.ok')}
          onOK={() => exportQrCodes()}
          onCancel={() => setShowModal('')}
        />
      )}

    </>
  );
};
export default Checkpoints;
