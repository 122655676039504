import {
  useTable,
  useFilters,
  useRowSelect,
  usePagination,
  useFlexLayout
} from 'react-table';
import { DefaultColumnFilter } from './Filters';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderComponent } from '../../components/LoaderComponent';
import { SelectSimple} from '../SelectReact';
import { CustomNumberFormatSimple } from '../NumberFormat';
import { Button } from 'react-bootstrap';

// Create a default prop getter
const defaultPropGetter = () => ({});

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input
          type="checkbox"
          ref={resolvedRef}
          {...rest}
          style={{ verticalAlign: 'middle', alignContent: 'center' }}
        />
      </>
    );
  }
);

export const CheckboxTablePaginated = ({
  columns,
  data,
  minRows,
  total,
  loading,
  filterTable,
  // initialState,
  getRowProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  checkboxHook = true,
  onSelectedRowsChange,
  pagination,
  fetchData,
  currentpage,
  pageCount,
  autoResetSelectedRows,
  ...props
}) => {
  // Use the state and functions returned from useTable to build your UI
  const { t } = useTranslation();

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 150,
      width: 150,
      maxWidth: 250,
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .includes(String(filterValue).toLowerCase())
            : true;
        });
      },
      filterOperator: (rows, id, filterValue) => {
        if (!filterValue[1]) return rows;
        return rows?.filter((row) => {
          const rowValue = row.values[id].toFixed(2);
          switch (filterValue[0]) {
            case '=':
              return rowValue == filterValue[1];
            case '<':
              return rowValue < filterValue[1];
            case '>':
              return rowValue > filterValue[1];
            default:
              return true;
          }
        });
      },
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    selectedFlatRows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    isAllRowsSelected,
    state: { selectedRowIds, pageIndex, pageSize, filters },
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      disableFilters: filterTable ? false : true,
      defaultColumn,
      autoResetSelectedRows: autoResetSelectedRows ? autoResetSelectedRows : false,
      manualPagination: true,
      pageCount: pageCount,
      manualFilters: true,
      autoResetPage: false,
      autoResetFilters: false,
    },
    useFilters,
    useFlexLayout,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (checkboxHook) {
        hooks.visibleColumns.push((columns) => {
          return [
            {
              id: 'selection',
              disableResizing: true,
              minWidth: 35,
              width: 35,
              maxWidth: 35,
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                  <IndeterminateCheckbox
                    {...getToggleAllRowsSelectedProps()}
                    id="all"
                  />
                </div>
              ),
              Cell: ({ row }) => (
                <div className="text-center">
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...columns,
          ];
        });
      }
    }
  );

  useEffect(() => {
    fetchData({ pageIndex, pageSize, filters });
  }, [fetchData, pageIndex, pageSize, filters]);

  useEffect(() => {
    onSelectedRowsChange &&
      onSelectedRowsChange(
        selectedFlatRows.map((d) => d.original.id),
        isAllRowsSelected
      );
  }, [selectedFlatRows.length, selectedRowIds, isAllRowsSelected]);

  const getRowStyle = (row) => {
    if(row.original.id === props.selectedId) {
      return ({backgroundColor: "#f7f6f3"})
    }
  }

  const renderPagination = () => {
    return (
      <div className="paginationTable">
        <div className="pagination-previous">
          <Button
            onClick={() => previousPage()}
            className="btn btn-sm pagination-button"
            disabled={!canPreviousPage}
          >
            {' '}
            {t('tables.previousText')}
          </Button>
        </div>
        <div className="pagination-center">
          <span className="pageInfo">
            {t('tables.pageText')}{' '}
            <div className="pageJump">
              {' '}
              <CustomNumberFormatSimple
                value={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                integer={true}
                defaultValue={pageIndex + 1}
                style={{ width: '50px' }}
                className="form-control custom-form-control custom-form-control-small-padding"
              />
            </div>{' '}
            {t('tables.ofText')}{' '}
            <span className="totalPages">{pageCount}</span>
          </span>
          <div style={{ width: '100px' }}>
            <SelectSimple
              options={[
                { label: `10 ${t('tables.rowsText')}`, value: 10 },
                { label: `20 ${t('tables.rowsText')}`, value: 20 },
                { label: `50 ${t('tables.rowsText')}`, value: 50 },
                { label: `100 ${t('tables.rowsText')}`, value: 100 },
              ]}
              value={pageSize || 10}
              onChange={(e) => {
                setPageSize(e.target.value);
              }}
              placeholder=""
              menuPortalTarget={document.body}
              style={{ width: '100px' }}
            />
          </div>
        </div>
        <div className="pagination-next">
          <Button
            onClick={() => nextPage()}
            className="btn btn-sm pagination-button"
            disabled={!canNextPage}
          >
            {' '}
            {t('tables.nextText')}
          </Button>
        </div>
      </div>
    );
  };

   // Render the UI for your table
   return (
    <>
    <LoaderComponent 
      loading={loading}
      textStyle={{fontSize: "16px"}}
      >
    <>
      <div className="full-width">
        <div className="paginated-table">
          <div {...getTableProps()} className="table" key={props.key} id={"table-portal"}>
            <div className="thead">
              {headerGroups.map(headerGroup => (
                <div {...headerGroup.getHeaderGroupProps()} className="tr">
                  {headerGroup.headers.map(column => (
                    <div {...column.getHeaderProps()} className="th">
                      <div className="header-text">
                        {column.render('Header')}
                      </div>
                      <div className="filter-row">
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </div>
                    
                  ))}
                </div>
              ))}
            </div>

            {!loading && data.length === 0 ? <div {...getTableBodyProps()} className="tbody">
            <div className="no-rows">{t('tables.noDataText')}</div></div> :
            <div {...getTableBodyProps()} className="tbody">
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <div {...row.getRowProps(getRowProps(row))} 
                  className="tr" 
                  style={{ ...row.getRowProps().style, ...getRowStyle(row) }} 
                  onClick={() => null} >
                    {row.cells.map(cell => {
                      return (
                      <div {...cell.getCellProps()} className="td">
                        {cell.render("Cell")}
                      </div>
                      )
                    })}

                  </div>
                )
              })}
            </div>
            }
          </div>
        </div>
      </div>
     {pagination && renderPagination()}
     </>
    </LoaderComponent>
    </>
  )
};
