import React, { useState, useRef, useCallback, useMemo } from 'react';
import APIs from '../../api/apiCalls';
import { CheckboxTablePaginated } from '../../components/ReactTable/ReactTableServerSidePagination';

const QuizzesTable = (props) => {
  const {
    columns,
    refresh,
    selectedQuiz,
    selectedCampaign,
    onSelectedRowsChange,
    status,
  } = props;
  const [quizzes, setQuizzes] = useState([]);
  const fetchIdRef = useRef(0);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(({ pageIndex, pageSize, filters }) => {
    setLoading(true);
    const fetchId = ++fetchIdRef.current
    if (fetchId === fetchIdRef.current) {
      let queryObj = {};
      if(filters && filters.length > 0){
        filters.forEach(function(filter){
          if(filter?.value) {
            queryObj[filter.id] = filter.value;
          }
        })
      }
      queryObj['status'] = status;
      queryObj['_pagesize'] = pageSize;
      queryObj['_page'] = pageIndex + 1;
      getCampaignQuizzes(queryObj);
    }
  }, [refresh, selectedCampaign]);

  const getCampaignQuizzes = async(queryObj) =>  {
    try {
      let res = await APIs.getCampaignQuizzes(selectedCampaign, queryObj);
      setPageCount(res.headers.total_pages)
      setQuizzes(res.data);
      setLoading(false);
      return res.data;
    } catch (err) {
      console.error(err);
      setLoading(false)
    }
  }


  return (
    <>
      <CheckboxTablePaginated
        columns={columns}
        data={useMemo(() => quizzes)}
        total={false}
        filterTable={true}
        pagination={true}
        selectedId={selectedQuiz?.id}
        checkboxHook={true}
        fetchData={fetchData}
        pageCount={pageCount}
        loading={loading}
        onSelectedRowsChange={(rows) =>
          onSelectedRowsChange(rows)
        }
        autoResetSelectedRows={true}
      />
    </>
  );
}
export default QuizzesTable;
